import React from "react"
import { graphql, StaticQuery } from "gatsby"
import ArchiveLayout from "../components/archivelayout"
import SEO from "../components/seo"
import Sidebar from "../components/sidebar"
import PaginationLinks from "../components/PaginationLinks"
import { Container, Row, Col } from "reactstrap"
import Post from "../components/Post"
import {seo} from "../../theme"

const postList = () => {
  const postsPerPage = 4
  let numberOfPages

  return (
    <ArchiveLayout>
      <SEO title={seo.blog.title} />
      <Container>
        <Row>
          <Col md="8">
            <StaticQuery
              query={indexQuery}
              render={(data) => {

                let posts = []

                data.allMdx.edges.map(({ node }) => {
                posts.push({...node})
                })
                
                posts.sort((a, b) => { 
                  if (a.publishedAt && b.frontmatter) {
                  return new Date(a.publishedAt) > new Date(b.frontmatter.date) ? -1 : 1;
                  } else {
                    return null
                  }
                });

                console.log(posts)

                const totalCount = data.allMdx.totalCount
                numberOfPages = Math.ceil(totalCount / postsPerPage)

                const isMdx = posts.frontmatter

                return (
                  <div>
                    {posts.map(node => (
                      <Post
                        title={node.frontmatter.title}
                        sizes={
                          node.frontmatter.featuredImage.childImageSharp.gatsbyImageData
                        }
                        date={node.frontmatter.date}
                        body={node.frontmatter.description}
                        slug={node.fields.slug}
                        tags={node.frontmatter.tags}
                      />
                    ))}
                    <PaginationLinks
                      currentPage={1}
                      numberOfPages={numberOfPages}
                    />
                  </div>
                );
              }}
            />
          </Col>

          <Col md="4">
            <Sidebar />
          </Col>
        </Row>
      </Container>
    </ArchiveLayout>
  );
}

const indexQuery = graphql`query indexQuery {
  allMdx(
    sort: {order: DESC, fields: [frontmatter___date]}
    limit: 4
  ) {
    totalCount
    edges {
      node {
        id
        frontmatter {
          title
          date(formatString: "MMMM DD, YYYY")
          tags
          description
          featuredImage {
            childImageSharp {
              gatsbyImageData(width: 500, height: 280, quality: 80, layout: CONSTRAINED)
            }
          }
        }
        fields {
          slug
        }
      }
    }
  }
}
`
export default postList
